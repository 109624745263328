import * as React from 'react';
import { Component, createRef } from 'react';
import { hideSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  backendURL,
  dateTimeFrom,
  mapStateToProps,
  redirect,
  setTableParams,
  transformToListPairs,
} from '../../core/utils';
import * as _ from 'lodash';
import { getStagesLanguages } from '../../redux/actions/table/stages';
import { withRouter } from 'react-router';
import styles from '../../styles/views/specifications/SpecificationSetItems.scss';
import Tooltip from '../../components/Tooltip';
import autobind from 'autobind-decorator';
import { getPreferences, updatePreferences } from '../../redux/actions/profile';
import {
  createGenericSpecification,
  deleteSpecificationSetItems,
  filterSpecificationSetItems,
  readSpecificationSet,
  readSpecificationSetDynamicColumns,
  readSpecificationSetItems,
} from '../../redux/actions/table/specification_sets';
import {
  listPossibleAddressBookManufacturers,
  listPossibleAddressBookSuppliers,
} from '../../redux/actions/table/address_book_companies';
import {
  listPossibleSpecificationResponsibleUsers,
  readWorkingSetSpecification,
} from '../../redux/actions/table/working_set_specifications';
import TableSpecificationSetItems from './helpers/TableSpecificationSetItems';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import classNames from 'classnames';
import Dropdown from '../../components/Dropdown';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import SpecificationSetsFlyout from '../../flyouts/specification_sets/SpecificationSetsFlyout';
import WorkingSetSpecificationsFlyout from '../../flyouts/working_set_specifications/WorkingSetSpecificationsFlyout';
import { getBOQArea } from '../../redux/actions/table/working_set_boq';
import { downloadFile } from '../../redux/actions/general/downloader';
import { setNotification } from '../../redux/actions/general/notification';
import { setPlusActions } from '../../redux/actions/general/plus';
import queryString from 'query-string';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

// ICONS
import ArrowLeftMiddleIcon from '../../assets/images/arrow-left-middle-15x15.svg';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import ColumnSelectorIcon from '../../assets/images/column-selector-16x16.svg';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import SpecificationsIcon from '../../assets/images/specifications-24x24.svg';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import ExportIcon from '../../assets/images/export-15x15.svg';
import AddSpecificationSetTemplateIcon from '../../assets/images/boq-save-as-template-16x16.svg';
import FilterIcon from '../../assets/images/filter-15x15.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import UserIcon from '../../assets/images/user-16x16.svg';
import TaskStatusIcon from '../../assets/images/task-status-15x15.svg';
import NeutralIcon from '../../assets/images/neutral-15x15.svg';

@mapStateToProps((state) => ({
  store: state.table['specification_sets'],
  specification_id: _.get(state.table, [
    'working_set_specifications',
    'clicked_row',
  ]),
  user_id: state.auth.id,
  dropdown: state.general.dropdown.shown,
}))
@withRouter
class SpecificationSetItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused_id: null,
      space_allocation: {},
      expanded_rows: [],
      isDataLoaded: false,
      active_level: 1,
      levels: [],
      columns: [],
    };

    this.tableRef = createRef();
    this.nameRefs = [];

    this.savePreferencesTimeout = null;
  }

  @autobind
  _refreshSpecificationSetItems() {
    readSpecificationSetItems(this.props.match.params.specification_set);

    hideDropdown();
  }

  componentDidMount() {
    listActiveModules();

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/specifications/sets')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/specifications/sets')
    );

    getStagesLanguages(this.props.match.params.stage).then(({ response }) => {
      setTableParams('specification_sets', {
        project_languages: response.data,
        language_id: _.findKey(response.data, (primary) => primary == true),
      });
    });

    readSpecificationSet(this.props.match.params.specification_set);

    setTableParams('specification_sets', {
      disabled_columns: [],
    });

    readSpecificationSetItems(this.props.match.params.specification_set)
      .then(({ response }) => {
        readSpecificationSetDynamicColumns(
          this.props.match.params.specification_set
        );

        this.setState({ preview: response.data });
      })
      // .then(() => {
      //   this._refreshSpecificationSetItems(true);
      // })
      .then(() => {
        getPreferences(
          `table.specification_set_items.${this.props.match.params.specification_set}`
        )
          .then(({ response: { data } }) => {
            const expanded_rows = _.get(data, 'expanded_rows', []);

            this.tableRef.setExpandedRowKeys(expanded_rows);
            this.setState({ expanded_rows });

            this.setState({ columns: this.tableRef.props?.columns });

            const user_preference_params = _.transform(
              data,
              (params, value, key) => {
                if (_.startsWith(key, 'filter_')) {
                  params[key.replace('filter_', 'filter.')] = _.toString(value);
                } else {
                  params[key] = value;
                }
              },
              {}
            );

            setTableParams(
              'specification_sets',
              _.pick(user_preference_params, [
                'disabled_columns',
                'pinned_columns',
                'view',
              ])
            );

            this.setState({
              expanded_rows,
              tempDisabledColumns: user_preference_params.disabled_columns,
            });

            const url_parameters = queryString.parse(
              this.props.history.location.search
            );

            const filterParams = _.pickBy(url_parameters, (value, key) =>
              _.startsWith(key, 'filter.')
            );

            if (!_.isEmpty(filterParams)) {
              _.forEach(filterParams, (value, key) => {
                setTableParams('specification_sets', { [key]: value });
              });

              this._filterSpecificationSetItems();
            }
          })
          .then(() => {
            const levels = {
              2: [],
              3: [],
            };

            _.each(this.props.store.items, (group) => {
              !_.isEmpty(group.children) && levels[2].push(group.id);

              _.each(group.children, (subgroup) => {
                !_.isEmpty(subgroup.children) && levels[3].push(subgroup.id);
              });
            });

            this.setState({ levels });

            for (let i = 2; i <= 3; i++) {
              if (
                _.every(levels[i], (id) =>
                  _.includes(this.state.expanded_rows, id)
                )
              ) {
                this.setState({ active_level: i });
              }
            }
          });
      });

    listPossibleAddressBookManufacturers().then(({ response }) => {
      this.setState({
        possible_address_book_manufacturers: transformToListPairs(
          response.data
        ),
      });
    });

    listPossibleAddressBookSuppliers().then(({ response }) => {
      this.setState({
        possible_address_book_suppliers: transformToListPairs(response.data),
      });
    });

    listPossibleSpecificationResponsibleUsers(
      this.props.match.params.stage
    ).then(({ response }) => {
      this.setState({
        possible_specification_responsible_users: transformToListPairs(
          response.data
        ),
      });
    });

    getBOQArea(this.props.match.params.stage).then(({ response }) => {
      this.setState({ space_allocation: response.data });
    });

    const plus_actions = [
      {
        label: __('specification-set-item.label.add-generic-specification'),
        tooltip: __('specification-set-item.tooltip.add-generic-specification'),
        disabled: false,
        onClick: () => this._createGenericSpecification(),
      },
      {
        label: __('specification-set.label.add-specification'),
        tooltip: __('specification-set.tooltip.add-specification'),
        disabled: false,
        onClick: () => this._addSpecification(),
      },
      {
        label: __('specification-set.label.add-existing-specifications'),
        tooltip: __('specification-set.tooltip.add-existing-specifications'),
        disabled: false,
        onClick: () => this._addExistingSpecifications(),
      },
    ];

    setPlusActions(
      _.map(plus_actions, (button, i) => {
        return {
          name: button.label,
          onClick: () => button.onClick(),
          disabled: button.disabled,
        };
      })
    );

    hideSidebar();
  }

  @autobind
  _saveTablePreferences() {
    if (this.savePreferencesTimeout) clearTimeout(this.savePreferencesTimeout);

    this.savePreferencesTimeout = setTimeout(() => {
      updatePreferences(
        `table.specification_set_items.${this.props.match.params.specification_set}`,
        {
          expanded_rows: this.state.expanded_rows,
        }
      );
    }, 1000);
  }

  @autobind
  _resetColumnsToDefault() {
    this.setState({
      tempDisabledColumns: _.map(
        _.filter(this.state.columns, ({ active }) => !active),
        'key'
      ),
    });
  }

  @autobind
  _createGenericSpecification(specification_subgroup_id) {
    if (specification_subgroup_id) {
      return createGenericSpecification(
        this.props.match.params.specification_set,
        {
          specification_subgroup_id,
        }
      ).then(() => {
        this._refreshSpecificationSetItems();
      });
    }

    return setTableParams('specification_sets', {
      create_generic_specification_wizard_active: true,
    });
  }

  @autobind
  _addSpecification(specification_subgroup_id = null) {
    setTableParams('working_set_specifications', {
      wizard_active: true,
      specification_subgroup_id,
    });
  }

  @autobind
  _addExistingSpecifications() {
    setTableParams('specification_sets', {
      add_existing_specifications_to_set_wizard_active: true,
    });
  }

  @autobind
  _toggleFilterFlyout() {
    setTableParams('specification_sets', {
      showFilters: !this.props.store.showFilters,
    });
  }

  @autobind
  _filterSpecificationSetItems() {
    filterSpecificationSetItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.store.initialItemsData &&
      this.props.store.initialItemsData !== prevProps.store.initialItemsData &&
      this.state.active_level > 1 &&
      this.tableRef
    ) {
      const levels = {
        2: [],
        3: [],
      };
      _.each(this.props.store.initialItemsData, (group) => {
        !_.isEmpty(group.children) && levels[2].push(group.id);
        _.each(group.children, (subgroup) => {
          !_.isEmpty(subgroup.children) && levels[3].push(subgroup.id);
        });
      });

      const expanded_rows =
        this.state.active_level == 2 ? levels[2] : [...levels[2], ...levels[3]];

      this.setState({ expanded_rows, levels });

      this._saveTablePreferences();

      this.tableRef.setExpandedRowKeys(expanded_rows); //set expanded rows in dependency state
    }

    if (
      this.props.store.clicked_row &&
      this.props.store.clicked_row !== prevProps.store.clicked_row
    ) {
      if (!prevProps.store.clicked_row) {
        this.setState({ preview: undefined });
      }

      readSpecificationSet(this.props.store.clicked_row).then(
        ({ response }) => {
          setTableParams('specification_sets', {
            flyout: response.data,
          });
        }
      );
    }
  }

  @autobind
  groupActions(selected_rows) {
    const actions = [];

    actions.push([
      <Tooltip
        text={__(
          'specification-set-items.tooltip.icon.change-responsible-user-to-selected-specifications'
        )}
        key={_.uniqueId()}
      >
        <UserIcon
          onClick={(e) => {
            e.stopPropagation();

            setTableParams('specification_sets', {
              selected_specification_ids: selected_rows,
              edit_specifications_responsible_user_wizard: true,
            });
          }}
        />
      </Tooltip>,

      <Tooltip
        text={__(
          'specification-set-items.tooltip.icon.change-status-to-selected-specifications'
        )}
        key={_.uniqueId()}
      >
        <TaskStatusIcon
          onClick={(e) => {
            e.stopPropagation();

            setTableParams('specification_sets', {
              selected_specification_ids: selected_rows,
              edit_specifications_status_wizard: true,
            });
          }}
        />
      </Tooltip>,

      <Tooltip
        text={__(
          'specification-set-items.tooltip.icon.delete-selected-specifications'
        )}
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __(
                'specification-set-items.alert.delete-confirmation'
              ),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteSpecificationSetItems(selected_rows).then(() => {
                      setNotification(__('quantity.alert.deleted'));

                      readSpecificationSetItems(
                        this.props.match.params.specification_set
                      );
                    });

                    hideAlertbox();
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ]);

    return actions;
  }

  @autobind
  _toggleColumn(column) {
    this.setState({
      tempDisabledColumns: _.xor(this.state.tempDisabledColumns, [column]),
    });
  }

  @autobind
  _toggleAllColumns() {
    if (_.isEmpty(this.state.tempDisabledColumns)) {
      this.setState({
        tempDisabledColumns: _.map(
          _.filter(this.state.columns, (column) => !column.required),
          'key'
        ),
      });
    } else {
      this.setState({
        tempDisabledColumns: [],
      });
    }
  }

  render() {
    const {
      space_allocation,
      expanded_rows,
      focused_id,
      possible_address_book_manufacturers,
      possible_address_book_suppliers,
      possible_specification_responsible_users,
      active_level,
      levels,
      tempDisabledColumns,
    } = this.state;

    const {
      language_id,
      project_languages,
      project_currency,
      flyout,
      items,
      dynamic_columns,
      disabled_columns,
    } = this.props.store;
    const { localization, dropdown, specification_id, user_id } = this.props;

    const footer_buttons = [
      {
        label: __('specification-set-item.label.add-generic-specification'),
        tooltip: __('specification-set-item.tooltip.add-generic-specification'),
        disabled: false,
        onClick: () => this._createGenericSpecification(),
      },
      {
        label: __('specification-set.label.add-specification'),
        tooltip: __('specification-set.tooltip.add-specification'),
        disabled: false,
        onClick: () => this._addSpecification(),
      },
      {
        label: __('specification-set.label.add-existing-specifications'),
        tooltip: __('specification-set.tooltip.add-existing-specifications'),
        disabled: false,
        onClick: () => this._addExistingSpecifications(),
      },
    ];

    const specification_action_row = [
      {
        label: __('specification-set-item.button.add-generic-specification'),
        onClick: (specification_subgroup_id) =>
          this._createGenericSpecification(specification_subgroup_id),
      },
      {
        label: __('specification-set-item.button.add-specification'),
        onClick: (specification_subgroup_id) =>
          this._addSpecification(specification_subgroup_id),
      },
    ];

    const is_administrator = _.isObject(
      _.find(flyout?.administrators, ['id', user_id])
    );

    const project_language_id = _.findKey(
      project_languages,
      (primary) => primary == true
    );

    const filters = _.reject(
      _.map(this.props.store.item_filters, (filter, key) => {
        return {
          key: key,
          name: filter.name,
          type: filter.type,
          nullable: filter.nullable,
          pinned: _.get(this.props.store.pinned_columns, key),
          items: _.map(filter.items, (value, key) => {
            return {
              value: key,
              label: value,
            };
          }),
          defaultItems: _.map(filter.defaultItems, (value, key) => {
            return {
              value: key,
              label: value,
            };
          }),
        };
      }),
      (filter) =>
        filter.type == 'sidebar' ||
        (filter.type == 'select' && _.isEmpty(filter.items))
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.set}>
              <span>
                <strong>
                  {'IS-' + _.padStart(flyout?.identifier, 4, '0')}:
                </strong>
                {_.get(
                  flyout?.title,
                  _.findKey(
                    _.get(flyout, 'project_languages'),
                    (primary) => primary == 1
                  )
                )}
              </span>
            </div>
            {flyout?.start_date && (
              <div className={styles.details}>{`${dateTimeFrom(
                flyout?.start_date
              )} - ${dateTimeFrom(flyout?.end_date)}`}</div>
            )}
          </div>
          <div className={styles.toolbar}>
            <div
              className={styles.left}
              onClick={() => {
                redirect(
                  '/stages/' +
                    this.props.match.params.stage +
                    '/specifications/sets'
                );
              }}
            >
              <ArrowLeftMiddleIcon />{' '}
              {__('specification-sets.tools.back-to-specification-set-list')}
            </div>
            <div className={styles.right}>
              <Tooltip
                text={__(
                  'specification-sets.tooltip.icon.specification-set-template'
                )}
              >
                <AddSpecificationSetTemplateIcon
                  className={styles.template}
                  onClick={() =>
                    setTableParams('specification_sets', {
                      add_specification_set_template_wizard_active: true,
                    })
                  }
                />
              </Tooltip>

              <div className={styles.separator} />

              <Tooltip
                text={__(
                  'specification-sets.tooltip.icon.export-specification-set'
                )}
                key={_.uniqueId()}
              >
                <ExportIcon
                  className={styles.export}
                  onClick={(e) => {
                    e.stopPropagation();

                    setNotification(
                      'specification-sets.notification.export-specification-set'
                    );

                    downloadFile(backendURL + '/specifications/sets/export', {
                      specification_set_id:
                        this.props.match.params.specification_set,
                    });
                  }}
                />
              </Tooltip>

              <div className={styles.separator} />

              <Tooltip
                text={__(
                  'specification-sets.tooltip.icon.specification-set-properties'
                )}
              >
                <InfoPositiveIcon
                  className={styles.info}
                  onClick={() =>
                    setTableParams('specification_sets', {
                      clicked_row: flyout.id,
                      stage_id: this.props.match.params.stage,
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className={styles.heading}>
          <div className={styles.title}>
            <span>
              {__('specification-set.items.specification-set' + flyout?.status)}
            </span>
          </div>
          <div className={styles.details}>
            <div className={styles.target}>
              <SpecificationsIcon className={styles.icon} />
              <Tooltip
                text={__(
                  'specification-sets.tooltip.icon.specification-set-properties'
                )}
              >
                <span
                  onClick={() =>
                    setTableParams('specification_sets', {
                      clicked_row: flyout.id,
                      stage_id: this.props.match.params.stage,
                    })
                  }
                >
                  {'IS-' + _.padStart(flyout?.identifier, 4, '0')}
                  <ArrowDoubleRightSmallIcon />
                </span>
              </Tooltip>
            </div>

            {!_.isEmpty(filters) && (
              <div className={styles.filter}>
                <Tooltip text={__('content.tooltip.table.filters')}>
                  <FilterIcon onClick={this._toggleFilterFlyout} />
                </Tooltip>
              </div>
            )}

            <div className={styles.columnSelector}>
              <Dropdown
                closable={false}
                top={29}
                right={-19}
                name='column_selector'
                header={
                  <div className={styles.columnSelectorHeader}>
                    {__('table.columns.select-columns')}

                    {!_.isEmpty(tempDisabledColumns) &&
                      _.size(tempDisabledColumns) !==
                        _.size(
                          _.filter(
                            this.state.columns,
                            (column) => !column.required
                          )
                        ) && (
                        <NeutralIcon
                          onClick={this._toggleAllColumns}
                          className={styles.neutral}
                        />
                      )}
                    {_.size(tempDisabledColumns) ===
                      _.size(
                        _.filter(
                          this.state.columns,
                          (column) => !column.required
                        )
                      ) && (
                      <UncheckedIcon
                        onClick={this._toggleAllColumns}
                        className={styles.unchecked}
                      />
                    )}
                    {_.isEmpty(tempDisabledColumns) && (
                      <CheckedIcon
                        onClick={this._toggleAllColumns}
                        className={styles.checked}
                      />
                    )}
                  </div>
                }
                content={
                  <div className={styles.columnSelectorContent}>
                    {_.map(
                      _.filter(
                        this.state.columns,
                        ({ required, key }) => !required && key != 'placeholder'
                      ),
                      ({ title, key }) => (
                        <div
                          onClick={() => this._toggleColumn(key)}
                          key={key}
                          className={styles.column}
                        >
                          {title}

                          {_.includes(this.state.tempDisabledColumns, key) && (
                            <UncheckedIcon className={styles.unchecked} />
                          )}
                          {!_.includes(this.state.tempDisabledColumns, key) && (
                            <CheckedIcon className={styles.checked} />
                          )}
                        </div>
                      )
                    )}
                    <div
                      onClick={this._resetColumnsToDefault}
                      className={styles.column}
                    >
                      {__('table.columns.reset-to-default')}
                    </div>
                    <div className={styles.saveFooter}>
                      <ButtonGroup right>
                        <Button
                          lightGray
                          medium
                          middleText={__('button.cancel')}
                          onClick={() => {
                            hideDropdown();
                          }}
                        />
                        <Button
                          lightBlue
                          medium
                          middleText={__('button.done')}
                          onClick={() => {
                            setTableParams('specification_sets', {
                              disabled_columns:
                                _.size(this.state.tempDisabledColumns) > 0
                                  ? this.state.tempDisabledColumns
                                  : null,
                            });

                            readSpecificationSetItems(
                              this.props.match.params.specification_set
                            );

                            hideDropdown();
                          }}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                }
              >
                <Tooltip text={__('content.tooltip.table.columns')}>
                  <ColumnSelectorIcon />
                </Tooltip>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className={styles.table}>
          <TableSpecificationSetItems
            name='specification_set_items'
            title={__('specification_set_items')}
            localization={localization}
            languageId={language_id}
            projectCurrency={project_currency}
            focusedId={focused_id}
            data={items}
            dynamicColumns={dynamic_columns}
            disabledColumns={disabled_columns}
            project_languages={project_languages}
            spaceAllocationData={space_allocation}
            setFocusedId={(focused_id) => this.setState({ focused_id })}
            setRef={(ref) => (this.tableRef = ref)}
            tableRef={this.tableRef}
            expandedRows={expanded_rows}
            setActiveLevel={(active_level) => {
              this.setState({ active_level });
            }}
            setExpandedRows={(expanded_rows) => {
              this.setState({ expanded_rows });

              this._saveTablePreferences();
            }}
            setNameRef={(id, ref) => (this.nameRefs[id] = ref)}
            possible_address_book_manufacturers={
              possible_address_book_manufacturers
            }
            possible_address_book_suppliers={possible_address_book_suppliers}
            possible_specification_responsible_users={
              possible_specification_responsible_users
            }
            refreshData={this._refreshSpecificationSetItems}
            specificationActionRow={specification_action_row}
            dropdown={dropdown}
            disabled={_.includes(['closed', 'canceled'], flyout?.status)}
            filterData={this._filterSpecificationSetItems}
            active_level={active_level}
            levels={levels}
            groupActions={this.groupActions}
            selectedRows={this.props.store.selected_rows}
            // store={this.props.store}
            // filters={filters}
            // refreshData={this._refreshWorkingSetBOQ}
            // languageSwitcher={language_switcher}
            // setLanguageSwitcher={(value) =>
            //   this.setState({ language_switcher: value })
            // }
            // dropdown={dropdown}
            // addRowButtons={add_row_buttons}
            // sidebar={this.props.sidebar}
            // possible_boq_groups={this.state.possible_boq_groups}
            // possible_boq_subgroups={this.state.possible_boq_subgroups}
            // handleCreateTemplateByLevel={this._handleCreateTemplateByLevel}
            // nameRefs={this.nameRefs}
          />
        </div>

        {is_administrator && (
          <div className={styles.footer}>
            {_.map(footer_buttons, (button, i) => (
              <div
                className={classNames(
                  styles.item,
                  classNames(button.disabled && styles.disabled)
                )}
                key={i}
                onClick={() => {
                  !button.disabled && button.onClick();
                  hideDropdown();
                }}
              >
                <Tooltip text={button.tooltip}>
                  <button>{button.label}</button>
                </Tooltip>
              </div>
            ))}
          </div>
        )}

        <div
          className={classNames(
            styles.flyout,
            this.props.store.clicked_row && styles.active
          )}
        >
          {this.props.store.clicked_row && (
            <SpecificationSetsFlyout
              data={this.props.store.flyout}
              readAction={(specification_set_id) => {
                return new Promise((resolve) => {
                  readSpecificationSet(
                    this.props.match.params.specification_set
                  ).then(({ response }) => {
                    setTableParams('specification_sets', {
                      flyout: response.data,
                    });

                    readSpecificationSetItems(
                      this.props.match.params.specification_set
                    ).then(({ response }) => {
                      resolve(response);
                    });
                  });
                });
              }}
            />
          )}
        </div>

        {specification_id && (
          <div className={styles.flyout}>
            <WorkingSetSpecificationsFlyout
              readAction={(specification_id) => {
                return new Promise((resolve) => {
                  readSpecificationSetItems(
                    this.props.match.params.specification_set
                  );
                });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SpecificationSetItems;
